import React from 'react';
import PropTypes from 'prop-types';
import { Visibility } from '../../../../utilities/components/visibility';

export const MapPopup = ({ element }) => (
    <address className="map-popup__address">
        <Visibility isVisible={ element.address_line_1 }>
            { element.address_line_1 }
            <br />
        </Visibility>
        <Visibility isVisible={ element.address_line_2 }>
            { element.address_line_2 }
            <br />
        </Visibility>
        <Visibility isVisible={ element.post_code && element.city }>
            { `${element.post_code} ${element.city}` }
        </Visibility>
        <table>
            <tbody>
                <Visibility isVisible={ element.phone_number_1 }>
                    <tr>
                        <td>T:</td>
                        <td>
                            <a href={ `tel:${element.phone_number_1}` }>
                                { element.phone_number_1 }
                            </a>
                        </td>
                    </tr>
                </Visibility>
                <Visibility isVisible={ element.phone_number_2 }>
                    <tr>
                        <td />
                        <td>
                            <a href={ `tel:${element.phone_number_2}` }>
                                { element.phone_number_2 }
                            </a>
                        </td>
                    </tr>
                </Visibility>
                <Visibility isVisible={ element.email }>
                    <tr>
                        <td>E:</td>
                        <td className="text--viridian-green">
                            <a className="text--underline" href={ `mailto:${element.email}` }>
                                { element.email }
                            </a>
                        </td>
                    </tr>
                </Visibility>
                <Visibility isVisible={ element.shop_url }>
                    <tr>
                        <td>{ (element.shop_url?.length <= 30) && 'W:' }</td>
                        <td className="text--viridian-green text--viridian-green-underline">
                            <a className="text--underline" href={ element.shop_url } target="_blank" rel="noreferrer">
                                {
                                    (element.shop_url?.length > 30)
                                        ? ('strona partnera')
                                        : (element.shop_url)
                                }
                            </a>
                        </td>
                    </tr>
                </Visibility>
            </tbody>
        </table>
    </address>
);

MapPopup.propTypes = {
    element: PropTypes.shape({
        id: PropTypes.string,
        address_line_1: PropTypes.string,
        address_line_2: PropTypes.string,
        post_code: PropTypes.string,
        city: PropTypes.string,
        phone_number_1: PropTypes.string,
        phone_number_2: PropTypes.string,
        email: PropTypes.string,
        shop_url: PropTypes.string
    }).isRequired
};