/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import { LocationInput } from 'utilities/components/maps-autocomplete/component';
import { GeolocationButton } from '../../geolocation-button/component/view';
import StarIcon from 'assets/images/star.svg';
import './view.scss';
import { FieldView } from 'utilities/components/filters/field/component/view';

export const MapFiltersView = ({
    isUsingGeolocation,
    toggleUseGeolocation,
    setCoords,
    filters,
    setFilters,
    searchedPlacePlaceholder
}) => (
    <div className="shops-map__filters">
        <div className="map-filters__input">
            <LocationInput onChange={ setCoords } searchedPlacePlaceholder={ searchedPlacePlaceholder } />
            <GeolocationButton onClick={ toggleUseGeolocation } isEnabled={ isUsingGeolocation } />
        </div>
        <div className="assortment-group">
            <div className="assortment-group__item">
                <FieldView
                    name="2"
                    filter="brands"
                    type="checkbox"
                    text="Premium wewnętrzne"
                    properties={ filters.brands }
                    setFilter={ setFilters }
                />
            </div>
            <div className="assortment-group__item">
                <FieldView
                    name="3"
                    filter="brands"
                    type="checkbox"
                    text="Optimo"
                    properties={ filters.brands }
                    setFilter={ setFilters }
                />
            </div>
            <div className="assortment-group__item">
                <FieldView
                    name="1"
                    filter="brands"
                    type="checkbox"
                    text="Premium zewnętrzne"
                    properties={ filters.brands }
                    setFilter={ setFilters }
                />
            </div>
            <div className="assortment-group__item">
                <FieldView
                    name="4"
                    filter="brands"
                    type="checkbox"
                    text="Smart"
                    properties={ filters.brands }
                    setFilter={ setFilters }
                />
            </div>
        </div>
        <div className="authorized-sale-point">
            <FieldView
                name="partner"
                filter="partner"
                type="icon-checkbox"
                text="Autoryzowany salon partnerski"
                icon={ StarIcon }
                properties={ filters.partner }
                setFilter={ setFilters }
            />
        </div>
    </div>
);

MapFiltersView.propTypes = {
    filters: PropTypes.instanceOf(Object).isRequired,
    setFilters: PropTypes.func.isRequired,
    isUsingGeolocation: PropTypes.bool.isRequired,
    toggleUseGeolocation: PropTypes.func.isRequired,
    setCoords: PropTypes.func.isRequired,
    searchedPlacePlaceholder: PropTypes.string
};

MapFiltersView.defaultProps = {
    searchedPlacePlaceholder: ''
};