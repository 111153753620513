import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './view.scss';

export const GeolocationButton = ({ isEnabled, ...props }) => {
    const iconClasses = classnames('geolocation-button__icon', {
        'geolocation-button__icon--enabled': isEnabled
    });

    const textClasses = classnames('geolocation-button__text', {
        'geolocation-button__text--enabled': isEnabled
    });

    return (
        <button className="geolocation-button" { ...props } type="button">
            <div className={ iconClasses }>
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 28 29">
                    <g fill="none" fillRule="evenodd" transform="translate(.5)">
                        <circle cx="14" cy="14.5" r="8.5" stroke="#62917B" strokeWidth="2" />
                        <path fill="#62917B" d="M13.5 0H14.5V5H13.5zM13.5 24H14.5V29H13.5z" />
                        <path fill="#62917B" d="M24 11.5H25V16.5H24z" transform="rotate(90 24.5 14)" />
                        <path fill="#62917B" d="M2 11.5H3V16.5H2z" transform="rotate(90 2.5 14)" />
                        <circle cx="14" cy="14.5" r="5.5" fill="#62917B" id="geo-icon-center-point" />
                    </g>
                </svg>
            </div>
            <div className={ textClasses }>
                { isEnabled ? 'Wyłącz' : 'Włącz' }
                <br />
                geolokalizację
            </div>
        </button>
    );
};

GeolocationButton.defaultProps = {
    isEnabled: false
};

GeolocationButton.propTypes = {
    isEnabled: PropTypes.bool
};