import React from 'react';
import { Navigation } from '../utilities/components/navigation/component';
import { Map } from '../features/shops-map/map/component';
import { Offer } from '../features/index/offer/component';
import { Footer } from '../utilities/components/footer/component';
import { MESSENGER_APP_ID, MESSENGER_PAGE_ID } from 'utilities/consts';
import MessengerCustomerChat from 'react-messenger-customer-chat';

import LogRocket from 'logrocket';
import SEO from 'utilities/components/seo';

import { Breadcrumbs } from 'utilities/components/breadcrumbs/view';
import { routes } from 'utilities/routes';

import '../features/shops-map/map/component/view.scss';
import { useSeoTexts } from 'utilities/hooks/useSeoTexts';
import { SeoDecription } from 'features/index/seo-description/view';

LogRocket.init('qijaj0/baranski');

const ShopsMap = () => {
    const setCanonicalLink = () => {
        let relCanonical = '';
        if (typeof window !== 'undefined') {
            relCanonical = `${window.location.origin}${window.location.pathname}`;
        }
        return relCanonical;
    };
    const { metaTitle, metaDescription, description } = useSeoTexts('Gdzie kupić');
    const breadcrumbs = [routes.home, routes.gdzieKupic];

    return (
        <main className="index">
            <SEO
                title={ metaTitle || 'Barański Drzwi - Gdzie kupić - Zobacz gdzie kupisz produkty Barański Drzwi' }
                description={
                    metaDescription
                    || 'Sprawdź gdzie kupisz nasze drzwi wewnętrzne, drzwi zewnętrzne czy drzwi klatkowe. Znajdź salony partnerskie w Twojej okolicy.'
                }
                canonicalLink={ setCanonicalLink() }
            />
            <Navigation />
            <Breadcrumbs breadcrumbs={ breadcrumbs } />
            <div className="shops-map">
                <Map />
            </div>
            <Offer />
            <SeoDecription seoDescription={ description } />
            <Footer />
            <MessengerCustomerChat pageId={ MESSENGER_PAGE_ID } appId={ MESSENGER_APP_ID } />
        </main>
    );
};

export default ShopsMap;
