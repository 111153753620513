import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Visibility } from '../../../../utilities/components/visibility';
import './view.scss';
import { sortBy } from 'lodash';

export const MapStock = ({ stocks }) => (
    <Visibility isVisible={ stocks.length > 0 }>
        <div className="map-stock">
            <h3 className="map-stock__title">
                W salonie zobaczysz drzwi:
            </h3>
            <div className="map-stock__wrapper">
                {sortBy(stocks, ['name']).map((item) => {
                    const indicatorClasses = classNames({
                        'map-stock__indicator': true,
                        'map-stock__indicator--indoor-premium': item.alternative_id === 2,
                        'map-stock__indicator--outdoor-premium': item.alternative_id === 1,
                        'map-stock__indicator--optimo': item.alternative_id === 3,
                        'map-stock__indicator--smart': item.alternative_id === 4
                    });
                    return (
                        <div className="map-stock__item" key={ item.alternative_id }>
                            <div className={ indicatorClasses } aria-hidden="true" />
                            { item.name }
                        </div>
                    );
                })}
            </div>
        </div>
    </Visibility>
);

MapStock.propTypes = {
    stocks: PropTypes.arrayOf(
        PropTypes.shape({
            alternative_id: PropTypes.number,
            name: PropTypes.string
        })
    ).isRequired
};