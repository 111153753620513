import React, {
    useState,
    useEffect,
    useMemo
} from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

import { Link } from 'gatsby';
import { Img } from 'utilities/components/img/component';

import arrow from 'assets/images/arrow--black.svg';
import magnifyingGlass from 'assets/images/magnifying-glass.svg';

import './view.scss';

export const FieldView = ({
    name,
    type,
    placeholder,
    text,
    logo,
    icon,
    value,
    filter,
    properties,
    options,
    isVisible,
    setVisibility,
    setValue,
    setFilter,
    setProperty,
    toggleVisibility,
    extendedOptionsInformation,
    isBestseller,
    isFavorite
}) => {
    // this state has been added for friendly links setting
    const [isChecked, setIsChecked] = useState(false);
    useEffect(() => {
        if (typeof properties !== 'string') {
            setIsChecked(properties.has(name));
        }
    }, [properties]);

    const debouncedChangeHandler = useMemo(() => debounce((searchValue) => setValue('set', filter, searchValue), 500), []);

    // added extended options list for select field. If the select has "extendedOptionsInformation" prop -> then it expects options not as an array,
    // but as array of objects with options and isLink keys, example:
    // [
    //     { option: 'Wewnętrzne', isLink: false },
    //     { option: 'Zewnętrzne', isLink: false },
    //     { option: 'Klatkowe i techniczne', isLink: false },
    //     { option: 'Klamki', isLink: true, link: '/produkty/klamki' }
    // ]
    // Then it checks if each option isLink, and if it is link - render it as Link element

    switch (type) {
        case 'checkbox':
            return (
                <label
                    className={
                        classnames('field__checkbox', {
                            'field__checkbox--brand': logo
                        })
                    }
                    htmlFor={ name }
                >
                    <input
                        className="checkbox__input"
                        id={ name }
                        name={ name }
                        type="checkbox"
                        checked={ isChecked }
                        onChange={ (event) => {
                            if (event.target.checked) {
                                setFilter('set', filter, name);
                            } else {
                                setFilter('unset', filter, name);
                            }
                        } }
                    />
                    <div className={
                        classnames('checkbox__ballot-box',
                            { 'checkbox__ballot-box--favorite': isFavorite && !isBestseller },
                            { 'checkbox__ballot-box--bestseller': isBestseller && !isFavorite })
                    }
                    />
                    {
                        (logo)
                            ? (<Img className="checkbox__logo" src={ logo } alt="logo" />)
                            : (<p className="checkbox__text checkbox__text--favorites">{ text || name }</p>)
                    }
                </label>
            );
        case 'select':
            return (
                <div
                    className={
                        classnames('field__select', {
                            'field__select--active': isVisible
                        })
                    }
                >
                    <select
                        className="select__input"
                        id={ name }
                        name={ name }
                        defaultValue={ value }
                    >
                        {
                            (filter === 'finish')
                                ? (options.map((currentValue) => (currentValue.name)).map((currentValue, index) => (<option value={ currentValue } key={ index }>{ currentValue }</option>)))
                                // added extended options list part
                                : (extendedOptionsInformation
                                    ? options.map((currentValue, index) => (<option value={ currentValue?.option } key={ index }>{ currentValue?.option }</option>))
                                    : options.map((currentValue, index) => (<option value={ currentValue } key={ index }>{ currentValue }</option>)))
                        }
                    </select>
                    <button
                        className={
                            classnames('select__ballot-box', {
                                'select__ballot-box--colored': value && value.size !== 0,
                                'select__ballot-box--finish': filter === 'finish'
                            })
                        }
                        type="button"
                        onClick={ () => {
                            toggleVisibility();
                        } }
                    >
                        {
                            (filter === 'finish')
                                ? (
                                    (options.filter((currentValue) => (currentValue.name === value))[0]?.image)
                                        ? (<Img className="select__image" src={ `${process.env.GATSBY_URL}/${options.filter((currentValue) => (currentValue.name === value))[0]?.image}` } alt={ value } />)
                                        : (null)
                                )
                                : (null)
                        }
                        { (value === '' || value.size === 0) ? name : value}
                        <Img className="select__icon" src={ arrow } alt="strzałka" />
                    </button>
                    <div
                        className={
                            classnames({
                                select__overlay: filter === 'finish'
                            })
                        }
                    >
                        <ul
                            className={
                                classnames('select__list', {
                                    'select__list--finish': filter === 'finish'
                                })
                            }
                        >
                            {
                                (filter === 'finish')
                                    ? (
                                        <>
                                            <li className="select__item">
                                                <button
                                                    className="select__button select__button--finish"
                                                    type="button"
                                                    style={{
                                                        justifyContent: 'center',
                                                        backgroundColor: 'white'
                                                    }}
                                                >
                                                    Rodzaj wykończenia:
                                                </button>
                                            </li>
                                            {
                                                options.map((currentValue, index) => (
                                                    <li className="select__item" key={ index }>
                                                        <button
                                                            className={ classnames(
                                                                'select__button select__button--finish',
                                                                { 'current-finish': value === currentValue?.name }
                                                            ) }
                                                            type="button"
                                                            onClick={ () => {
                                                                if (value === currentValue?.name) {
                                                                    setVisibility(false);
                                                                } else {
                                                                    setProperty({ [filter]: currentValue?.name });
                                                                }
                                                            } }
                                                        >
                                                            {
                                                                (currentValue?.image)
                                                                    ? (<Img className="select__image" src={ `${process.env.GATSBY_URL}/${currentValue?.image}` } alt={ currentValue?.name } />)
                                                                    : (null)
                                                            }
                                                            { currentValue?.name }
                                                        </button>
                                                    </li>
                                                ))
                                            }
                                        </>
                                    )
                                    // added extended options list part
                                    : (extendedOptionsInformation
                                        ? options.map((currentValue, index) => (
                                            <li className="select__item" key={ index }>
                                                { currentValue?.isLink
                                                    ? (
                                                        <Link
                                                            className="select__button"
                                                            type="button"
                                                            to={ currentValue?.link }
                                                        >
                                                            { currentValue?.option }
                                                        </Link>
                                                    )
                                                    : (
                                                        <button
                                                            className="select__button"
                                                            type="button"
                                                            onClick={ () => {
                                                                setValue('set', filter, currentValue?.option);
                                                            } }
                                                        >
                                                            { currentValue?.option }
                                                        </button>
                                                    )}
                                            </li>
                                        ))
                                        : options.map((currentValue, index) => (
                                            <li className="select__item" key={ index }>
                                                <button
                                                    className="select__button"
                                                    type="button"
                                                    onClick={ () => {
                                                        setValue('set', filter, currentValue);
                                                    } }
                                                >
                                                    { currentValue }
                                                </button>
                                            </li>
                                        ))
                                    )
                            }
                        </ul>
                    </div>
                </div>
            );
        case 'search':
            return (
                <label className="field__search" htmlFor={ name }>
                    <Img className="search__icon" src={ magnifyingGlass } alt="lupa" />
                    <input
                        className="search__input"
                        id={ name }
                        name={ name }
                        type="text"
                        placeholder={ placeholder }
                        defaultValue={ value }
                        onChange={ (event) => debouncedChangeHandler(event.target.value) }
                    />
                </label>
            );
        case 'icon-checkbox':
            return (
                <label className={ classnames('field__checkbox', { 'field__checkbox--brand': logo }) } htmlFor={ name }>
                    <input
                        className="checkbox__input"
                        id={ name }
                        name={ name }
                        type="checkbox"
                        checked={ properties.has(name) }
                        onChange={ (event) => {
                            if (event.target.checked) {
                                setFilter('set', filter, name);
                            } else {
                                setFilter('unset', filter, name);
                            }
                        } }
                    />
                    <div className="checkbox__ballot-box" />
                    {
                        (icon) && <Img src={ icon } alt="" className="checkbox__icon" />
                    }
                    {
                        (text) && <p className="checkbox__text checkbox__text--bolder">{text}</p>
                    }
                </label>
            );
        default:
            return (
                <label className="field__common" htmlFor={ name }>
                    { text || name }
                    <input
                        className="common__input"
                        id={ name }
                        name={ name }
                        placeholder={ placeholder }
                        type="text"
                        value={ value }
                        onChange={ (event) => {
                            setValue('set', filter, event.target.value);
                        } }
                    />
                </label>
            );
    }
};

FieldView.defaultProps = {
    isVisible: false,
    toggleVisibility: () => null,
    setVisibility: () => null,
    icon: null,
    isBestseller: false,
    isFavorite: false
};

FieldView.propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    logo: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    filter: PropTypes.string.isRequired,
    properties: PropTypes.instanceOf(Set).isRequired,
    options: PropTypes.instanceOf(Array).isRequired,
    isVisible: PropTypes.bool,
    setVisibility: PropTypes.func,
    setValue: PropTypes.func.isRequired,
    setFilter: PropTypes.func.isRequired,
    setProperty: PropTypes.func.isRequired,
    toggleVisibility: PropTypes.func,
    icon: PropTypes.string,
    extendedOptionsInformation: PropTypes.bool.isRequired,
    isBestseller: PropTypes.bool,
    isFavorite: PropTypes.bool
};