import MarkerClusterer from '@googlemaps/markerclustererplus';
import { useLocation, navigate } from '@reach/router';
// import CloseIcon from 'assets/images/close.svg';
import mapPinPartner from 'assets/images/map-pin-partner.svg';
import mapPin from 'assets/images/map-pin.svg';
import StarIcon from 'assets/images/star.svg';
import mapStyles from 'assets/map/mapStyle.json';
import { MapPopup } from 'features/shops-map/map-popup/component/view';
import { MapStock } from 'features/shops-map/map-stock/component/view';
import { graphql, useStaticQuery } from 'gatsby';
import queryString from 'query-string';
import React, {
    useEffect, useMemo, useRef, useState
} from 'react';
import ReactDOMServer from 'react-dom/server';
import { Visibility } from 'utilities/components/visibility';
import { googleServices } from 'utilities/googleMaps';
import { useLocalStorage } from '../../../../utilities/hooks/useLocalStorage';
import { MapFiltersView } from '../../filters/component/view';
import { paths } from 'utilities/routes';
import { Img } from 'utilities/components/img/component';

import './view.scss';

const CLUSTER_STYLES = [
    {
        url: '',
        width: 46,
        height: 46,
        className: 'cluster cluster--small',
        textSize: 20,
        textColor: '#fff'
    },
    {
        url: '',
        width: 56,
        height: 56,
        className: 'cluster cluster--medium',
        textSize: 24,
        textColor: '#fff'
    },
    {
        url: '',
        width: 94,
        height: 94,
        className: 'cluster cluster--large',
        textSize: 40,
        textColor: '#fff'
    }
];

export const Map = () => {
    const INITIAL_CENTER_POSITION = { lat: 51.919438, lng: 19.145136 };
    const INITIAL_ZOOM = 6;
    const [useUserLocation, setUseLocation] = useLocalStorage(process.env.GATSBY_LOCATION_KEY, false);
    const { search } = useLocation();
    const query = useMemo(() => queryString.parse(search), [search]);
    const mapRef = useRef(null);
    const mapServiceRef = useRef();
    const [markerClusterer, setMarkerClusterer] = useState(null);
    const [gmaps, setGmaps] = useState(null);
    const [filters, setFilters] = useState({
        brands: new Set(),
        partner: new Set()
    });

    const items = useStaticQuery(graphql`
        query GetAllShops {
            allShops(filter: {id: {ne: "dummy"}}) {
                nodes {
                    id
                    longitude
                    latitude
                    name
                    address_line_1
                    address_line_2
                    post_code
                    city
                    phone_number_1
                    phone_number_2
                    email
                    assortments {
                        name
                        alternative_id
                    }
                    is_partner
                    shop_url
                }
            }
        }
    `);

    const getFilteredShops = useMemo(() => {
        const values = items.allShops.nodes;
        return values
            .filter((item) => (filters.brands.size > 0 ? item.assortments.find((assortment) => filters.brands.has(String(assortment.alternative_id))) : true))
            .filter((item) => ((filters.partner.size === 1) ? item.is_partner === 1 : true));
    }, [filters]);

    const handleSetCoords = (lat, lng) => {
        if (mapServiceRef.current) {
            const coords = { lat, lng };
            mapServiceRef.current.panTo(coords);
        }
    };

    const handleZoom = (newZoom) => {
        if (mapServiceRef.current) {
            mapServiceRef.current.setZoom(newZoom);
        }
    };

    const handleNavigateToPlace = (lat, lng, newZoom = 13) => {
        handleSetCoords(lat, lng);
        handleZoom(newZoom);
    };

    useEffect(() => {
        if (mapServiceRef.current && window.navigator.geolocation && useUserLocation) {
            window.navigator.geolocation.getCurrentPosition((position) => {
                const coords = {
                    lat: Number(position.coords.latitude),
                    lng: Number(position.coords.longitude)
                };
                mapServiceRef.current.panTo(coords);
                mapServiceRef.current.setZoom(11);

                // if we are on the /gdize-kupic page and the geolocation has changed from window-navigation - remove the query params
                if (typeof window !== 'undefined') {
                    if (window.location?.pathname.includes(paths.gdzieKupic)) {
                        navigate(paths.gdzieKupic);
                    }
                }
            });
        }
    }, [useUserLocation]);

    const toggleUseLocation = () => {
        setUseLocation(!useUserLocation);
    };

    const setFilter = (action, key, value) => {
        setFilters((previousFilters) => {
            let filter;

            switch (action) {
                case 'set':
                    filter = ((typeof previousFilters[key]) === 'string')
                        ? (value)
                        : (new Set([...previousFilters[key], value]));
                    break;
                case 'unset':
                    filter = ((typeof previousFilters[key]) === 'string')
                        ? ('')
                        : (new Set([...previousFilters[key]].filter((currentValue) => (currentValue !== value))));
                    break;
            }

            return ({
                ...previousFilters,
                [key]: filter
            });
        });
    };

    useEffect(() => {
        googleServices.then((newGmaps) => {
            if (!mapRef.current) return;

            if (!mapServiceRef.current) {
                setGmaps(newGmaps);
                const newMap = new newGmaps.maps.Map(mapRef.current, { center: INITIAL_CENTER_POSITION, zoom: INITIAL_ZOOM });
                mapServiceRef.current = newMap;
            }
        });
    }, []);

    useEffect(() => {
        if (!mapRef.current) return;

        const mapServiceRefCurrent = mapServiceRef.current;
        if (mapServiceRefCurrent && gmaps) {
            mapServiceRefCurrent.setOptions({ styles: mapStyles });
            const newMarkers = getFilteredShops.map((item) => {
                const infoWindow = new gmaps.maps.InfoWindow({});

                const marker = new gmaps.maps.Marker({
                    position: {
                        lat: Number(item.latitude),
                        lng: Number(item.longitude)
                    },
                    mapServiceRefCurrent,
                    // eslint-disable-next-line eqeqeq
                    icon: (item?.is_partner == 1) ? mapPinPartner : mapPin
                });

                marker.addListener('click', (markerEvent) => {
                    const lat = markerEvent.latLng.lat();
                    const lng = markerEvent.latLng.lng();
                    mapServiceRefCurrent.panTo({ lat, lng });
                });

                marker.addListener('click', () => {
                    const content = ReactDOMServer.renderToString(
                        <>
                            <h2 className="map-popup__title">
                                {item.name}
                            </h2>
                            {/* <button type="button" aria-label="Zamknij" className="map-popup__close-button">
                                <Img src={ CloseIcon } alt="" />
                            </button> */}
                            <Visibility isVisible={ Boolean(item.is_partner) }>
                                <p className="map-popup__subtitle">
                                    <Img src={ StarIcon } alt="" />
                                    Autoryzowany salon partnerski
                                </p>
                            </Visibility>
                            <MapPopup element={ item } />
                            <MapStock stocks={ item.assortments } />
                        </>
                    );

                    infoWindow.setContent(content);
                    infoWindow.open(mapServiceRefCurrent, marker);
                });
                return marker;
            });

            if (markerClusterer) {
                markerClusterer.clearMarkers();
            }

            const newMarkerClusterer = new MarkerClusterer(mapServiceRefCurrent, newMarkers, {
                styles: CLUSTER_STYLES
            });

            setMarkerClusterer(newMarkerClusterer);
        }
    }, [mapRef, gmaps, getFilteredShops]);

    useEffect(() => {
        if (mapServiceRef.current && gmaps && query.lat && query.lng) {
            setTimeout(() => {
                const coords = { lat: Number(query.lat), lng: Number(query.lng) };
                handleNavigateToPlace(coords.lat, coords.lng, 9);
                setUseLocation(false);
            }, 500);
        }
    }, [gmaps, query]);

    return (
        <>
            <h1 className="map__headline">
                Mapa salonów partnerskich
            </h1>
            <MapFiltersView
                isUsingGeolocation={ useUserLocation }
                toggleUseGeolocation={ toggleUseLocation }
                filters={ filters }
                setFilters={ setFilter }
                setCoords={ handleNavigateToPlace }
                searchedPlacePlaceholder={ query.searchedPlace }
            />
            <div id="map" className="map" ref={ mapRef } />
        </>
    );
};